import React from "react";

import GamesIcon from "../assets/images/footer-icon/foot-games.svg";
import GamesIconActive from "../assets/images/footer-icon/foot-games-ac.svg";
import SportsIcon from "../assets/images/footer-icon/foot-sports.svg";
import SportsIconActive from "../assets/images/footer-icon/foot-sports-ac.svg";
import HomeIcon from "../assets/images/Profileicon.png";
import CashierIcon from "../assets/images/footer-icon/foot-cashier.svg";
import CashierIconActive from "../assets/images/footer-icon/foot-cashier-ac.svg";
import ProfileIcon from "../assets/images/footer-icon/foot-profile.svg";
import ProfileIconActive from "../assets/images/footer-icon/foot-profile-ac.svg";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "cashier",
    "support",
    "all-game",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  return (
    <>
      <div className="footer d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 ">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "all-game" ? "active" : ""}
                href="/all-game"
              >
                <img
                  src={GamesIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={GamesIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Games</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "sportsbook/Cricket" ? "active" : ""}
                href="/sportsbook/Cricket"
              >
                <img
                  src={SportsIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={SportsIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Sports</span>
              </a>
            </li>
            <li className="home">
              <a className="active" href="/sports">
                <img src={HomeIcon} alt="Foot Nav Icon" />
                {/* <span>Home</span> */}
              </a>
            </li>
            <li>
              <a
                className={activePage === "cashier" ? "active" : ""}
                href="/cashier"
              >
                <img
                  src={CashierIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={CashierIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a href="/support-customer">
                <img
                  src={ProfileIcon}
                  alt="Foot Nav Icon"
                  className="no_active"
                />
                <img
                  src={ProfileIconActive}
                  alt="Foot Nav Icon"
                  className="active"
                />
                <span>24/7 Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
