import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_CONST } from "../config/const";
export const Toaster = (type, message) => {
  if (type === APP_CONST.TOAST_TYPE.ERROR) {
    toast.error(message);
  } else if (type === APP_CONST.TOAST_TYPE.SUCCESS) {
    toast.success(message);
  } else if (type === APP_CONST.TOAST_TYPE.INFO) {
    toast.info(message);
  } else if (type === APP_CONST.TOAST_TYPE.WARNING) {
    toast.warning(message);
  } else {
    toast.error(message);
  }
};
