import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function CasinoCard({ data, imageUrlCheck = false }) {
  console.log("slots");
  const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState(false);
  const { isAuth } = useSelector((state) => state.auth);

  const getredirectionURL = (data) => {
    if (isAuth) {
      const redirectUrl = data?.href || data?.redirectUrl || data?.cover || "#";

      navigate(redirectUrl);
    } else {
      setShowAuthModals(true);
      // navigate("#");
    }
  };
  const getImageUrl = (url) => {
    if (imageUrlCheck && url) {
      return url?.slice(1, url.length) || url;
    }
    return url || "";
  };
  return (
    <>
      <a onClick={() => getredirectionURL(data)} className="casino_games">
        <div className="shadow rounded position-relative">
          <img
            src={getImageUrl(data?.cover?.src || data?.imgUrl)}
            alt={data?.cover?.alt || ""}
          />

          <p>{data?.GameName || data?.name}</p>

          <div class="overlayslideright">
            <div class="textslideright">Play Now</div>
          </div>
        </div>
      </a>
    </>
  );
}
